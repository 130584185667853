
  <template>
    <nav class="navbar navbar-expand-lg position-fixed w-100 shadowed-header">
      <div class="container-fluid">
        <!-- Sidebar toggle button for mobile -->
        <button
          class="btn btn-outline-secondary d-lg-none me-2"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasSidebar"
          aria-controls="offcanvasSidebar"
        >
          ☰
        </button>
        
        <!-- Logo and Brand Name -->
        <router-link to="/" class="navbar-brand d-flex align-items-center">
          <img src="@/assets/logo2.png" alt="Logo" class="logo me-2" />
        </router-link>
        
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarContent"
          aria-controls="navbarContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        
        <div class="collapse navbar-collapse" id="navbarContent">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item">
              <span class="nav-link text-dark">Welcome, {{email}}</span>
            </li>
            <li class="nav-item">
              <button class="btn btn-outline-primary d-flex align-items-center"  @click="logout">
                <i class="bi bi-box-arrow-right me-2"></i> Logout
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
   </template>
   <script setup>
import {  computed } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()


const email = computed (()=> {
  return localStorage.getItem("username");
});

        const logout = () => {
            localStorage.removeItem("email_id");
            localStorage.removeItem("loginresponse");
            caches.keys().then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))));

            router.push("/");
        }
 
</script>

   <style scoped>
   .shadowed-header {
    background-color: var(--header-bg-color);
  color: var(--text-dark-color);
  border-bottom-right-radius: 8px; /* Rounded corners */
  
  border-bottom-left-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 26px rgba(1,46,145,255); /* Subtle shadow for elevation */
  padding: 10px 15px; /* Adjust padding if needed */
   }
   .navbar {
     height: 60px;
     z-index: 1050; /* Ensure header is above the sidebar */
   }
   /* Logo styling */
.logo {
  height: 40px; /* Adjust as needed for logo size */
}
   </style>

  