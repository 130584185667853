<template>
    <div class="position-fixed vh-100 d-none d-lg-flex flex-column p-3  sidebar">
        <ul class="nav nav-pills flex-column mb-4 flex-grow-1 mt-8">
          <li class="nav-item mb-2 text-white" v-for="item in sidebarItems" :key="item.name">
            <router-link
              :to="item.link"
              class="nav-link text-white"
              active-class="active"
            >
            <h6><i :class="'fs-4 ' + item.icon"></i>&nbsp;  {{ item.name }}</h6>
            </router-link>
          </li>
        </ul>
        <div class="mt-auto pt-3 border-top text-white">
            Powered by <a href="#" target="blank" class="link-info link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">Stepping Stones</a>
        
        </div>
      </div>
    
  
    
      <!-- Offcanvas Sidebar for mobile screens -->
      <div
        class="offcanvas offcanvas-start text-white"
        tabindex="-1"
        id="offcanvasSidebar"
      >
        <div class="offcanvas-header">
          <h5 class="offcanvas-title">Admin Dashboard</h5>
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body d-flex flex-column p-3">
          <ul class="nav nav-pills flex-column mb-4 flex-grow-1">
            <li class="nav-item mb-2" v-for="item in sidebarItems" :key="item.name">
              <router-link
                :to="item.link"
                class="nav-link text-white"
                active-class="active"
                data-bs-dismiss="offcanvas"
              >
              <i :class="'fs-4 ' + item.icon"></i>&nbsp;  {{ item.name }}
              </router-link>
            </li>
          </ul>
          <div class="mt-auto pt-3 border-top text-white">
            Powered by <a href="#" target="blank" class="link-info link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">Stepping Stones</a>
          </div>
        </div>
      </div> 
        
    

</template>

<script>
export default {
    name: 'sidebar-page',
    data() {
        return {
            sidebarItems: [],
            apiUrl: process.env.VUE_APP_API_BASE_URL,
            loggedIn: false,
            email: "",
            toggleBoxVisible: false

        }
    },
    mounted() {
        const storedObject = localStorage.getItem("loginresponse");
        const loginResponse = JSON.parse(storedObject);
        if (loginResponse && loginResponse.data) {
            let keys = loginResponse.data;
            keys.forEach(key => {
                let formattedKey = key.replace('_', '  &  ');
                if (formattedKey === 'home') {
                    formattedKey = 'Home Page Settings';
                }
                let icon = '';
                switch (key) {
                    case 'user':
                        icon = 'bi bi-person-fill';
                        break;
                    case 'booking':
                        icon = 'bi bi-truck';
                        break;
                    case 'home':
                        icon = 'bi bi-box-fill';
                        break;
               
                }

                this.sidebarItems.push({
                    name: formattedKey.charAt(0).toUpperCase() + formattedKey.substr(1).toLowerCase() ,
                    link: '/' + key,
                    icon: icon
                })
            })
        }
        


    },
    methods: {
        closeSection() {
            this.$parent.$emit("closeSection")
        }
    }

}
</script>


<style scoped>

.sidebar {
    width: 300px; /* Set sidebar width */
    background-color: var(--sidebar-bg-color);
    /* Scrollable if content is longer than viewport */
  }
  .offcanvas {
    background-color: var(--sidebar-bg-color);
   
  }
  .nav-link.active {
    background-color: #ffffff !important; /* Bootstrap primary color */
    color: var(--sidebar-bg-color) !important; /* White text for active link */
  }
.active-link {
  
    background: rgba(255, 255, 255, 0.22);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(0.6px);
    -webkit-backdrop-filter: blur(0.6px);
    border: 1px solid rgba(255, 255, 255, 0.22);
    padding: 5px 20px 5px 0px; 
    color: #ffffff;
    
}
.sidenav ul{
    padding-left: 0.5rem;
}

.sidenav {
    background-color: #f1f1f1 !important;
    height: 100% !important;
    
  }

.sidenav li {
    list-style: none;
    padding: 5px 20px 5px 0px; 
    
    font-family: displayregular;
    text-align: left; 
    width: 100%; 
}

.sidenav li a {
    text-decoration: none;
    color: #333333;
    font-size: .98rem;
    font-weight: 300;
    font-family: displayregular;
    display: block;
    padding: 5px 20px 5px 0px; 
  
}

.sidenav li a:hover {
    background: rgba(255, 255, 255, 0.22);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(0.6px);
    -webkit-backdrop-filter: blur(0.6px);
    border: 1px solid rgba(255, 255, 255, 0.22);
    padding: 5px 20px 5px 0px; 
    color: #ffffff;
    
}





.imge2 {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    border-style: double;
    border-color: rgb(5, 68, 37);
}

.small-box {
    background-color: #ffffff;
    height: 70px;
    border: 1px solid #ebe7e7;
   
   
    right: 12px;
    position: absolute;
}

.small-box ul {
    display: flex;
    justify-content: center; 
    align-items: center; 
    height: 100%; 
}

.small-box li {
    list-style: none;
    margin-right: 32px;
   
}

.small-box li a {
    color: #020b16;
    text-decoration: none;
    display: flex;
    align-items: center; 
}
.small-box li a:hover {
    background-color: #1a3077;
    padding: 8px;
    border-radius: 5px;
    color: #ffffff;
}
.email{
   margin-top: 10px;
}

@media screen and (max-width: 368px) {
    .sidenav {
        width: 100%;
        z-index: 1;
    }

    .router {
        margin-left: 250px;
    }
}


</style>
