import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { computed } from 'vue'
import login from '../views/login.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/' ,
    name: 'login',
    component: login
  },
  {
    path: '/user',
    name: 'usermaster',
   
    component: () => import( '../views/usermaster.vue'),
    
  },
  {
    path: '/booking',
    name: 'bookingmaster',
   
    component: () => import( '../views/bookingmaster.vue'),
    
  },
  {
    path:'/adduser',
    name:'adduser',
    component: ()=> import( '../views/userdialogbox.vue')
  },
  {
    path:'/edituser',
    props: true,
    name:'edituser',
    component: ()=> import( '../views/userdialogbox.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import( '../views/homepage.vue'),
    
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
const isAuthenticated = computed(()=> { 
  return localStorage.getItem('loginresponse')? true:false
})
router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && !isAuthenticated.value) {
    next({ name: 'login' })
  } 
  else {
      next()
  }
})
export default router
