
<template>
  <template v-if="route.path !== '/' ">
  <div class="d-flex">
    <sidebar class="bg-light"/>
    <div class=" flex-grow-1">
      <adminHeader />
      <main class="p-4 content-area">
        <router-view />
      </main>
    </div>
  </div>
  </template>
  <template v-if="route.path == '/' ">
    <div :class="{'router': route.path !== '/'}">
            <router-view/>
            
        </div>
      </template>
  </template>
<script setup>

import sidebar from '@/views/sidebar.vue'

import adminHeader from '@/views/adminHeader.vue'
import { useRoute } from 'vue-router'
import 'vue3-autocomplete/dist/vue3-autocomplete.css'

const route = useRoute() 

</script>


<style lang="scss">

:root {
  --sidebar-bg-color: #012e91; /* Light gray for sidebar */
  --header-bg-color: #ffffff; /* White for header */
  --content-bg-color: #f0f2f5; /* Very light gray for main content */
  --text-dark-color: #333333; /* Dark color for text */
  --primary-color: #012e91; /* Primary color */
}
/* Adjust the main content area to avoid overlap with the fixed sidebar and header */
/* Apply margin-left only on large screens and above */
.content-area {
  margin-left: 0; /* Default for smaller screens */
}

@media (min-width: 992px) { /* lg breakpoint */
  .content-area {
    margin-left: 290px; /* Offset for sidebar on larger screens */
  }
}

main {
  margin-top: 50px; /* Adjust for fixed header height */
}
@font-face {
  font-family: rounded;
  src: url('./fonts/SF-Pro-Rounded-Bold.otf');
 
}
@font-face {
  font-family: regular;
  src: url('./fonts/SF-Pro-Rounded-Regular.otf');
}

@font-face {
  font-family:public ;
  src: url('./fonts/PublicSans-VariableFont_wght.ttf');
};
@font-face {
    font-family: displaymedium;
    src: url('./fonts/SFPRODISPLAYMEDIUM.OTF');
}
@font-face {
    font-family: displaybold;
    src: url('./fonts/SFPRODISPLAYBOLD.OTF');
}
@font-face {
    font-family: displayregular;
    src: url('./fonts/SFPRODISPLAYREGULAR.OTF');
}
.fixTop {
  background-color: #111c43 !important;
  position: sticky !important;
  z-index: 1;
  top: 0;
}
.sidebar, .offcanvas {
  background-color: #111c43 !important;
 
}
.router{
   
    
  
  padding-top: 77px;
 
}
label{
    font-family: displaymedium;
    color: #070A13;
    font-weight: 400;
    font-size: 14px;
   
}

.dim-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 999; 
}


.box {
    
    background: white;
    text-transform: capitalize;
   }
::placeholder{
    font-size: 16px;
    font-weight: 50;
    font-family: displayregular;
    
    
}
ul.breadcrumb {
  padding: 10px 16px;
  list-style: none;
  background:#eeeef5 ;
  
 
  
 
}
ul.breadcrumb li {
  display: inline;
  font-size: 18px;
}
ul.breadcrumb li+li:before {
  padding: 8px;
  color: rgb(112, 110, 110);
  content: "/";
}
ul.breadcrumb li a {
  color: var(--sidebar-bg-color);
  text-decoration: none;
}
ul.breadcrumb li a:hover {
  color: var(--sidebar-bg-color);
  text-decoration: underline;
}
.navbar-toggler {
  background-color: whitesmoke !important;
}


.edit-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  
  
}

@media (max-width: 768px) {
  .edit-dialog {
    max-width: 100%;
    padding: 10px;
  }
}



.dustbin{
    color: red;
}
.fix{
  // background: #F5F5FA;
 background:#eeeef5 ;
 min-height: 100vh;
  
}


.addbutton {
   
  
    background-color: #213785;
    color: #F5F5FA;
    border: 2px solid #373075;
    padding: 7px 28px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 5px;

}



.addbutton:hover {
    background-color: #463bac;
    color: white;
}

.addbutton2 {
    background-color: #ffffff;
    border: 2px solid #CBD5E1;
    color:#070A13;
    padding: 7px 28px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 5px;
   
    
  

}



.addbutton2:hover {
    background-color: #ebeef1;
    color:#070A13;
}
.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
   
    color: #375aa5;
    height: 700px; 
  }
  .contents{
    position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  
}

.active-link {
  
  background: rgba(255, 255, 255, 0.22);
  border-radius: 5px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(0.6px);
  -webkit-backdrop-filter: blur(0.6px);
  border: 1px solid rgba(255, 255, 255, 0.22);
  padding: 5px 20px 5px 0px; 
  color: #ffffff;
  
}
.sidenav ul{
  padding-left: 0.5rem;
}

.imge {
  height: 45px !important;
  
}





.small-box {
  background-color: #ffffff;
  height: 70px;
  border: 1px solid #ebe7e7;
 
 
  right: 12px;
  position: absolute;
}

.small-box ul {
  display: flex;
  justify-content: center; 
  align-items: center; 
  height: 100%; 
}

.small-box li {
  list-style: none;
  margin-right: 32px;
 
}

.small-box li a {
  color: #020b16;
  text-decoration: none;
  display: flex;
  align-items: center; 
}
.small-box li a:hover {
  background-color: #1a3077;
  padding: 8px;
  border-radius: 5px;
  color: #ffffff;
}
.nav-link{
  color: #FFFFFF !important;
 
}

@media screen and (max-width: 368px) {
  .sidenav {
      width: 100%;
      z-index: 1;
  }

  .router {
      margin-left: 250px;
  }
}

@media screen and (max-width: 768px) {
  .imge {
    height: 25px !important;
    
  }
}
nav,
.offcanvas {
  background-color: #1e293b;
}
.navbar-toggler {
  border: none;
}
.navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}


.offcanvas.offcanvas-start {
  width: 75% !important;
}

.p-paginator {
  border-radius: 0px !important;
}
.dropdown-menu {
  right: 0px;
}

</style>
